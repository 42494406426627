<!--原材料进料明细表-->
<template>
    <div class="PurchaseBalanceDetails table-bg">
        <reportTable
            code="PurchaseBalanceDetails"
            :query="query"
            date-label="进场日期"
            @generateComplete="generateComplete"
            :percentage="percentage"
            :custom-date-method="customDateMethod"
            table-title="原材料进料明细表"
            :toolbar="['exportTable']"
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">日期选择：</label>
                    <el-date-picker
                        v-model="monthDate"
                        type="month"
                        placeholder="请选择"
                        value-format="yyyy-MM"
                        :editable="false"
                        :clearable="false"
                    >
                    </el-date-picker>
                </div>
                <div class="query-item">
                    <label class="name">出场日期：</label>
                    <el-date-picker
                        v-model="tareTime"
                        type="month"
                        placeholder="请选择"
                        @change="changeTareTime"
                        :clearable="true"
                    >
                    </el-date-picker>
                </div>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_ids"
                        placeholder="请选择"
                        clearable
                        multiple
                        collapse-tags
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料分类：</label>
                    <el-select
                        v-model="query.type_dic_code"
                        placeholder="请选择"
                        clearable
                        @change="getMaterialChild"
                    >
                        <el-option
                            v-for="item in materialGroupList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料子类：</label>
                    <el-select
                        v-model="query.child_type_code"
                        placeholder="请选择"
                        clearable
                        @change="getMaterialSpec"
                    >
                        <el-option
                            v-for="item in materialChildList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料规格：</label>
                    <el-select
                        v-model="query.spec_code"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in materialSpecList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <el-input placeholder="过磅编码/车牌号码/供应商" v-model="query.search_key"></el-input>
                </div>
            </template>
            <template #customTable>
                <div class="table-head">
                    <table class="t-table" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="width: 0.8rem">
                                    进场日期
                                </th>
                                <th style="width: 2rem">
                                    供货人
                                </th>
                                <th style="width: 0.8rem">
                                    毛重
                                </th>
                                <th style="width: 0.8rem">
                                    皮重
                                </th>
                                <th style="width: 0.8rem">
                                    料单数
                                </th>
                                <th style="width: 0.8rem">
                                    净重（吨）
                                </th>
                                <th style="width: 0.8rem">
                                    扣吨数
                                </th>
                                <th style="width: 0.8rem">
                                    单价（元）
                                </th>
                                <th style="width: 1rem">
                                    金额
                                </th>
                                <th style="width: 1rem">
                                    扣吨比例
                                </th>
                                <th style="width: 1rem">
                                    车牌号
                                </th>
                                <th style="width: 1.8rem">
                                    备注
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table-body">
                    <table
                        v-if="tableList.length>0"
                        class="t-table"
                        cellpadding="0"
                        cellspacing="0"
                    >
                        <tbody>
                            <template v-for="(item1,index1) in tableList">
                                <tr :key="index1">
                                    <td
                                        style="width: 0.8rem"
                                        v-if="mergeCells['data_date'+index1]!=0"
                                        :rowspan="mergeCells['data_date'+index1]"
                                    >
                                        {{ (item1.data_date || '').substring(0,11) }}
                                    </td>
                                    <td
                                        style="width: 2rem"
                                        v-if="mergeCells['supplier_name'+index1]!=0"
                                        :rowspan="mergeCells['supplier_name'+index1]"
                                    >
                                        {{ item1.supplier_name }}
                                    </td>
                                    <td style="width: 0.8rem">
                                        {{ item1.gross_weight }}
                                    </td>
                                    <td style="width: 0.8rem">
                                        {{ item1.tare_weight }}
                                    </td>
                                    <td style="width: 0.8rem">
                                        {{ item1.delivery_weight }}
                                    </td>
                                    <td style="width: 0.8rem">
                                        {{ item1.checked_net_weight }}
                                    </td>
                                    <td style="width: 0.8rem">
                                        {{ item1.adjusted_deduction_weight }}
                                    </td>
                                    <td style="width: 0.8rem">
                                        {{ item1.unit_price }}
                                    </td>
                                    <td style="width: 1rem">
                                        {{ item1.subtotal }}
                                    </td>
                                    <td style="width: 1rem">
                                        {{ item1.deduction_weight_ratio }}
                                    </td>
                                    <td style="width: 1rem">
                                        {{ item1.plate_number }}
                                    </td>
                                    <td style="width: 1.8rem"></td>
                                </tr>
                            </template>
                            <tr>
                                <td style="width: 0.8rem">
                                    合计
                                </td>
                                <td style="width: 2rem"></td>
                                <td style="width: 0.8rem">
                                    {{ totalData.gross_weight }}
                                </td>
                                <td style="width: 0.8rem">
                                    {{ totalData.tare_weight }}
                                </td>
                                <td style="width: 0.8rem">
                                    {{ totalData.delivery_weight }}
                                </td>
                                <td style="width: 0.8rem">
                                    {{ totalData.checked_net_weight }}
                                </td>
                                <td style="width: 0.8rem">
                                    {{ totalData.adjusted_deduction_weight }}
                                </td>
                                <td style="width: 0.8rem">
                                    {{ totalData.unit_price }}
                                </td>
                                <td style="width: 1rem">
                                    {{ totalData.subtotal }}
                                </td>
                                <td style="width: 1rem">
                                    {{ totalData.deduction_weight_ratio }}
                                </td>
                                <td style="width: 1rem">
                                    {{ totalData.plate_number }}
                                </td>
                                <td style="width: 1.8rem"></td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="el-table__empty-text"></div>
                </div>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import qs from 'qs';
import utils from '@/utils/util';
export default {
    components: { reportTable },
    data() {
        return {
            loading: false,
            query: {
                startTareTime: '',
                endTareTime: '',
                station_ids: [],
                type_dic_code: '',
                child_type_code: '',
                spec_code: '',
                search_key: '',
            },
            monthDate: '',
            tareTime: '',
            stationList: [],
            materialGroupList: [],
            materialChildList: [],
            materialSpecList: [],
            totalData: {},
            tableList: [],
            // 时间分批大小 ：天
            limit: 10,
            // 进度条
            percentage: 100,
            // 合并单元格配置
            mergeCells: {},
            // 查询分组
            queryArr: null,
        };
    },
    created() {
        this.monthDate = utils.timeFormat(new Date(), 'yyyy-MM');
        this.getStationList();
        this.getMaterialGroup();
    },
    methods: {
        // 自定义查询时间
        customDateMethod(params) {
            params.startTime = utils.timeFormat(new Date(this.monthDate), 'yyyy-MM-01');
            const maxDates = this.getMonthDays(this.monthDate);
            params.endTime = utils.timeFormat(new Date(this.monthDate), 'yyyy-MM-' + maxDates);
        },
        changeTareTime(val) {
            if (val) {
                this.query.startTareTime = utils.timeFormat(new Date(val), 'yyyy-MM-01');
                const maxDates = this.getMonthDays(val);
                this.query.endTareTime = utils.timeFormat(new Date(val), 'yyyy-MM-' + maxDates);
            } else {
                this.query.startTareTime = '';
                this.query.endTareTime = '';
            }
        },
        getMonthDays(dateStr) {
            const d = new Date(dateStr);
            return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
        },
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 原材料分类
        async getMaterialGroup() {
            this.materialGroupList = await this.$axios.get('/interfaceApi/procurement/material/group/type/get');
        },
        // 原材料子类
        async getMaterialChild() {
            this.query.child_type_code = '';
            this.query.spec_code = '';
            this.materialChildList = [];
            this.materialSpecList = [];
            const url = `/interfaceApi/procurement/material/group/type/child/get/parent_code?parent_code=${this.query.type_dic_code}`;
            const data = await this.$axios.get(url);
            if (data.length > 0) {
                this.materialChildList = data;
            }
        },
        // 原材料规格
        async getMaterialSpec() {
            this.query.spec_code = '';
            this.materialSpecList = [];
            const url = `/interfaceApi/procurement/material/group/type/spec/get/parent_code?parent_code=${this.query.child_type_code}`;
            const data = await this.$axios.get(url);
            if (data.length > 0) {
                this.materialSpecList = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.loading = true;
                this.tableList = [];
                this.mergeCells = {};
                this.percentage = 0;
                const startTimes = new Date(params.startTime).getTime();
                const endTimes = new Date(params.endTime).getTime();
                const splitTimes = this.limit * 24 * 60 * 60 * 1000;
                const arr = [];
                for (let times = startTimes; times <= endTimes; times += splitTimes) {
                    const start = utils.timeFormat(new Date(times), 'yyyy-MM-dd');
                    let endms = times + splitTimes;
                    if (endms >= endTimes) {
                        endms = endTimes;
                    }
                    const end = utils.timeFormat(new Date(endms), 'yyyy-MM-dd');
                    if (arr.length > 0 && start === end) {
                        break;
                    }
                    arr.push([start, end]);
                }
                this.queryArr = arr;
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const pam = Object.assign( {}, params);
                    pam.startTime = arr[i][0];
                    pam.endTime = arr[i][1];
                    pam.station_ids = pam.station_ids.join(',');
                    const listUrl = `/interfaceApi/report/material_purchase_details/data_list?${qs.stringify(pam)}`;
                    const list = await this.$axios.get(listUrl);
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    const pushList = [];
                    list.forEach(itemList => {
                        itemList.forEach(item => {
                            item.data_date = (item.data_date || '').substring(0, 11);
                            pushList.push(item);
                        });
                    });
                    this.tableList.push(...pushList);
                    this.mergeCellsFn(this.tableList);
                    if ( Number(i) === 0) {
                        this.loading = false;
                    }
                }
                if (this.tableList.length > 0) {
                    // 获取合计数据
                    const url = `/interfaceApi/report/material_purchase_details/data_total?${qs.stringify(params)}`;
                    const totalData = await this.$axios.get(url);
                    totalData.colspan = 2;
                    this.totalData = totalData;
                }

                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        // 合并单元格方法
        mergeCellsFn(list = []) {
            const mergeProps = ['data_date', 'supplier_name' ];
            list.forEach((item, index) => {
                for (const i in mergeProps) {
                    const prop = mergeProps[i];
                    const prevProp = mergeProps[i - 1];
                    const mergeKey = prop + '' + index;
                    if (this.mergeCells[mergeKey] || !item[prop]) {
                        continue;
                    }
                    let rowspan = 1;
                    let nextIndex = index + 1;
                    // eslint-disable-next-line max-len
                    while (list[nextIndex] && item[prop] === list[nextIndex][prop] && item[prevProp] === list[nextIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[nextIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        nextIndex++;
                    }
                    this.mergeCells[mergeKey] = rowspan;
                    let prevIndex = index - 1;
                    // eslint-disable-next-line max-len
                    while (list[prevIndex] && item[prop] === list[prevIndex][prop] && item[prevProp] === list[prevIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[prevIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        prevIndex--;
                        this.mergeCells[mergeKey] = 0;
                        break;
                    }

                }
            });
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>
</style>


